import { MegaMenuCallout } from "components/molecules/MegaMenuCallout";
import { MegaMenuLargeBlock } from "components/molecules/MegaMenuLargeBlock";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { Link } from "@components/atoms";
import { FC } from "react";
import { StaticRoutes } from "@routes";
import styled from "styled-components";
import {
  Column,
  TextBlock,
  Asset,
  ColumnContainer,
  RightColumn,
  Container,
  ResizableContainer,
} from "./styles";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";
import { useTranslations } from "next-intl";
import { useLocale } from "@hooks";
import { color } from "@styles";
import { P1 } from "components/atoms/Paragraph";

export const MegaMenuProducts: FC = () => {
  const t = useTranslations("shared/mega-menu");
  const { locale } = useLocale();
  const { products, productsExtended } = useLocaleData(data);
  return (
    <ResizableContainer>
      <StyledContainer>
        <ColumnContainer maxHeight="40.5rem">
          <Column>
            <TextBlock>
              <Link href={StaticRoutes.insuranceGroupInsurance}>
                <MegaMenuLargeBlock
                  title={t<string>("products.left.title")}
                  subheading={t<string>("products.left.subheading")}
                  desc={t<string>("products.left.desc")}
                  link={t<string>("products.left.link")}
                />
              </Link>
            </TextBlock>
            <Asset
              width={359}
              height={453}
              src={t<string>("products.left.asset.src")}
              alt={t<string>("products.left.asset.alt")}
              priority
            />
          </Column>
          <MiddleColumn>
            <MiddleTextBlock>
              {products
                .filter((item) => item.isLive === undefined || item.isLive === true)
                .map((product) => (
                  <Link key={product.key} href={product.href}>
                    <MegaMenuSmallBlock title={product.title} desc={product.desc} />
                  </Link>
                ))}
            </MiddleTextBlock>
          </MiddleColumn>
          {locale === "uk" && (
            <RightColumn>
              <TextBlock>
                <Link href={t<string>("products.right.link-src")}>
                  <MegaMenuLargeBlock
                    subheading={t<string>("products.right.subheading")}
                    title={t<string>("products.right.title")}
                    desc={t<string>("products.right.desc")}
                    link={t<string>("products.right.link")}
                    mb="1rem"
                  />
                </Link>
                <Link href={t<string>("products.right.second.link-src")}>
                  <MegaMenuLargeBlock
                    subheading={t<string>("products.right.second.subheading")}
                    title={t<string>("products.right.second.title")}
                    desc={t<string>("products.right.second.desc")}
                    link={t<string>("products.right.second.link")}
                  />
                </Link>
              </TextBlock>
            </RightColumn>
          )}
          {locale === "za" && (
            <RightColumn>
              <TextBlock>
                <Link href={t<string>("products.right.link-src")}>
                  <MegaMenuLargeBlock
                    title={t<string>("products.right.title")}
                    desc={t<string>("products.right.desc")}
                    link={t<string>("products.right.link")}
                  />
                </Link>
              </TextBlock>
              <Asset
                src={t<string>("products.right.asset.src")}
                alt={t<string>("products.right.asset.alt")}
                width={359}
                height={453}
              />
            </RightColumn>
          )}
          {locale === "us" ? (
            <>
              <RightColumn>
                <TextBlock>
                  {productsExtended.map((product) => (
                    <Link key={product.key} href={product.href}>
                      <MegaMenuSmallBlock
                        width="25.375rem"
                        title={product.title}
                        desc={product.desc}
                      />
                    </Link>
                  ))}
                </TextBlock>
              </RightColumn>
            </>
          ) : null}
        </ColumnContainer>
        <MegaMenuCallout
          pillText={t<string>("products.callout.pill")}
          desc={t<string>("products.callout.desc")}
          CTADesc={t<string>("products.callout.cta")}
          link={t<string>("products.callout.link")}
        />
      </StyledContainer>
    </ResizableContainer>
  );
};

const StyledContainer = styled(Container)`
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const MiddleColumn = styled(Column)`
  margin-bottom: 1.5rem;
`;

const MiddleTextBlock = styled.div`
  margin: 1.5rem 0rem 0rem 0rem;
`;

const Subheading = styled.div`
  padding-botton: 0.25rem;
`;

const Sub = styled(P1)`
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0;
  color: ${color.B2100};
`;
